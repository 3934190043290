import React from 'react';

import {
  wrap, description,
} from './style.module.css';
import Info from '../../../components/Info';

const Database = () => (
  <section className={wrap}>
    <div className="container-md">
      <Info
        title="데이터 기반의<br class='onlyPC'/> 의사결정"
        isFullWidth
      >
        <p className={description}>
          모든 의사결정은 명확한 가설과 측정 가능한 데이터를
          기반으로 이루어집니다. 뱅크샐러드 구성원은
          실험 결과를 토대로 개선점을 제안하고, 의사결정의
          기초로 활용할 수 있어야 합니다.
        </p>
        <p className={description}>
          이를 위해 모든 인원에게 데이터 측정 및 실험 설계에 대한 교육을 제공하며, 선도적인 A/B Testing Infra를 구축하여 다양한 실험을 지원합니다.
        </p>
      </Info>
    </div>
  </section>
);

export default Database;
