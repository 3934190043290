import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  wrap, title as styledTitle, contents, lists as styledLists,
} from './style.module.css';

const BenefitsContents = ({
  classProp = '',
  title,
  lists = [],
  children,
}) => (
  <div className={classnames(wrap, classProp)}>
    <h1
      className={styledTitle}
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-duration="300"
      data-sal-easing="ease"
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <p
      className={contents}
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-duration="300"
      data-sal-easing="ease"
    >
      {children}
    </p>
    {
      !!lists.length && (
        <div
          data-sal="slide-up"
          data-sal-delay="500"
          data-sal-duration="300"
          data-sal-easing="ease"
        >
          <ul
            className={styledLists}
          >
            {
              lists.map((item) => (
                <li key={`benefits_content_${item}`}>
                  {`· ${item}`}
                </li>
              ))
            }
          </ul>
        </div>
      )
    }
  </div>
);

BenefitsContents.propTypes = {
  classProp: PropTypes.string,
  title: PropTypes.string,
  lists: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
};

BenefitsContents.defaultProps = {
  classProp: '',
  title: '',
  lists: [],
  children: {},
};

export default BenefitsContents;
