import React from 'react';
import Hero from '../../components/Hero';
import HowWeWork from './HowWeWork';
import Evaluation from './Evaluation';
import Sprint from './Sprint';
import Database from './Database';
import Benefits from './Benefits';

import MAIN_HERO from '../../images/culture/main_hero.png';
import M_MAIN_HERO from '../../images/culture/m_main_hero.png';
import EVALUATION_HERO from '../../images/culture/evaluation_hero.png';
import M_EVALUATION_HERO from '../../images/culture/m_evaluation_hero.png';
import WORKFLOW_HERO from '../../images/culture/workflow_hero.png';
import M_WORKFLOW_HERO from '../../images/culture/m_workflow_hero.png';

const Culture = ({ ...props }) => (
  <div
    {...props}
  >
    <Hero
      subTitle="Our value"
      title="우리는 오로지<br />고객 임팩트에 몰입합니다."
      cover={MAIN_HERO}
      mCover={M_MAIN_HERO}
    />
    <HowWeWork />
    <Hero
      subTitle="Evaluation & Compensation"
      title="뱅크샐러드에서는<br />누구나 리더가 될 수 있습니다."
      cover={EVALUATION_HERO}
      mCover={M_EVALUATION_HERO}
      isSquareLayout
    />
    <Evaluation />
    <Hero
      subTitle="Workflow"
      title="우리는 가설과<br class='onlyMobile' /> 실험을 통해<br />매주 발전합니다."
      cover={WORKFLOW_HERO}
      mCover={M_WORKFLOW_HERO}
      isSquareLayout
    />
    <Sprint />
    <Database />
    <Benefits />
  </div>
);

export default Culture;
