import React from 'react';
import classNames from 'classnames';
import {
  wrap, whiteTheme, scrollText, contentsWrap, description,
} from './style.module.css';
import Info from '../../../components/Info';
import Content from '../../../components/Content';
import LottieView from '../../../components/Lottie';
import HOW_WE_WORK from '../../../images/lottie/how_we_work.json';
import M_HOW_WE_WORK from '../../../images/lottie/m_how_we_work.json';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import DATA from '../../../data';

const HowWeWork = () => {
  const browserWidth = useBrowserWidth();

  return (
    <section className={wrap}>
      <div className="container-md">
        <div className={whiteTheme}>
          <Info
            subTitle="How we work"
            title="여정 자체가 보상이 되는<br />문화를 만듭니다."
            isFullWidth
          >
            개별 프로젝트는 하나의 스타트업을 운영하는 것과 같습니다.
            오너십을 가지고 자율적으로 의사결정하되, 그 결과는 겸허히 마주합니다.
            팀단위로 빠르게 가설을 실험하고 고객 임팩트를 창출합니다.
            그 여정에서 개인의 커리어 역시 성장할 것입니다.
          </Info>
        </div>
      </div>
      <div className={scrollText}>
        <LottieView
          lottieId="impact_scroll"
          autoplay
          loop
          animationData={
            browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET)
              ? M_HOW_WE_WORK
              : HOW_WE_WORK
          }
        />
      </div>
      <div className="container-md">
        <div className={classNames('row', contentsWrap, whiteTheme)}>
          <Content
            key="work_1"
            title="Impact"
          >
            <p className={description}>
              작은 &#39;새로 고침&#39;이 아닌 고객 경험을
              <br />
              &#39;혁신(WOW)&#39;하는 데 집착합니다.
            </p>
            <p className={description}>
              데이터로 철저하게 성공 가능성을 예측합니다.
            </p>
          </Content>
          <Content
            key="work_2"
            title="Transparency"
          >
            <p className={description}>
              효율적인 의사 결정을 위해
              <br />
              &#39;필요한 맥락&#39;을 투명하게 공유합니다.
            </p>
            <p className={description}>
              피드백은 솔직하게 결정은 줏대 있게 합니다.
            </p>
          </Content>
          <Content
            key="work_3"
            title="Ownership"
          >
            <p className={description}>
              넓은 시야를 가지고 내 일의 &#39;끝&#39;을 봅니다.
            </p>
            <p className={description}>
              공동의 목표를 달성하기 위해
              <br />
              내 일의 &#39;경계&#39;는 없습니다.
            </p>
          </Content>
          <Content
            key="work_4"
            title="Fearlessness"
          >
            <p className={description}>
              언제나 한발 더 나아가 좋은 것을 넘어
              <br />
              위대한 것을 추구합니다.
            </p>
            <p className={description}>
              저렴한 실패를 통해 값지게 배우고
              <br />
              성공으로 이루어냅니다.
            </p>
          </Content>
          <Content
            key="work_5"
            title="Respect"
          >
            <p className={description}>
              모두에게 선한 의도가 있음을 믿습니다.
            </p>
            <p className={description}>
              프로답게 일하고 원팀으로 해결합니다.
            </p>
          </Content>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
