import React from 'react';

import classNames from 'classnames';
import {
  wrap, onlyPC, image, contentWrap, content, containerWrap,
} from './style.module.css';
import Info from '../../../components/Info';
import CHART_URL from '../../../images/culture/evaluation_chart.png';
import M_CHART_URL from '../../../images/culture/m_evaluation_chart.png';

const Evaluation = () => (
  <section className={wrap}>
    <div className="container-md">
      <Info
        title="구성원의 시장 가치를<br />높여주는 회사"
        isFullWidth
      >
        회사의 성장만큼 개인의 커리어도 성장해야 합니다.
        각자의 목표와 역량을 고려한 커리어 개발 플랜과 함께
        다음 단계로 도약하기 위한 멘토링을 제공합니다.
      </Info>
      <div className={containerWrap}>
        <img
          src={CHART_URL}
          alt="뱅크샐러드 역량 승진"
          className={classNames(image, 'onlyPC')}
        />
        <img
          src={M_CHART_URL}
          alt="뱅크샐러드 역량 승진"
          className={classNames(image, 'onlyMobile')}
        />
        <div className={contentWrap}>
          <article className={content}>
            <h1>
              고객 임팩트에 기반한
              <br className={onlyPC} />
              { ' 승진제도' }
            </h1>
            <p>
              본인의 레벨을 뛰어넘는 역량과 성과를 지속적으로 입증할 경우,
              <br className={onlyPC} />
              { ' 다음 레벨로의 승진이 가능합니다. 고객 임팩트를 발휘한다면,' }
              <br className={onlyPC} />
              { ' 근속연수나 나이와 상관없이 누구나 뱅크샐러드의 리더가 될 수 있습니다.' }
            </p>
          </article>
          <article className={content}>
            <h1>
              임팩트를 측정하는
              <br className={onlyPC} />
              { ' 명확한 기준' }
            </h1>
            <p>
              누군가의 주관적인 의견으로 성과와 평가가 정해지지 않습니다.
              <br className={onlyPC} />
              { ' 개인의 성장과 평가가 일치되도록, 명확한 직급/직책별 역량을' }
              <br className={onlyPC} />
              { ' 제공하고 이를 바탕으로 업무 성과를 360도 평가합니다.' }
            </p>
          </article>
        </div>
      </div>
    </div>
  </section>
);

export default Evaluation;
