import React from 'react';

import classNames from 'classnames';
import { wrap, image, description } from './style.module.css';
import Info from '../../../components/Info';
import GRAPH_URL from '../../../images/culture/sprint_graph.png';
import M_GRAPH_URL from '../../../images/culture/m_sprint_graph.png';

const Sprint = () => (
  <section className={wrap}>
    <div className="container-md">
      <Info
        title="분기 별로 계획하는 OKR과<br />반기마다 이루어지는 평가"
        isFullWidth
      >
        <p className={description}>
          고객 기회를 발굴하고 테스트하기에 1년은 우리에겐 너무 긴 호흡입니다.
          뱅크샐러드는 연간 전사 OKR을 기반으로,
          각 조직이 분기별로 OKR을 계획하고 회고합니다.
        </p>
        <p className={description}>
          또한 1년의 중간 시점에는 나아가고 있는 방향을 Check 하며
          더 잘하기 위한 방법을 함께 논의하고, 어떻게 성장해야 하는지를 고민합니다.
          1년을 마무리하는 시점에는 구성원의 업적과 역량 성장에 대해 평가하고
          고성과자와 승진자를 발굴합니다.
        </p>
      </Info>
      <img
        src={GRAPH_URL}
        alt="뱅크샐러드 스프린트 그래프"
        className={classNames(image, 'onlyPC')}
      />
      <img
        src={M_GRAPH_URL}
        alt="뱅크샐러드 스프린트 그래프"
        className={classNames(image, 'onlyMobile')}
      />
    </div>
  </section>
);

export default Sprint;
