import React from 'react';

import Slider from 'react-slick';
import classNames from 'classnames';
import {
  wrap, article, articleLayout, imgPortrait, imgLayout, contentsLayout,
} from './style.module.css';
import Info from '../../../components/Info';
import BenefitsContents from './Contents';
import useBrowserWidth from '../../../hooks/useBrowserWidth';

import A_1 from '../../../images/culture/benefits/2022_a_1.jpeg';
import A_2 from '../../../images/culture/benefits/2022_a_2.jpeg';
import A_3 from '../../../images/culture/benefits/2022_a_3.jpeg';
import B_1 from '../../../images/culture/benefits/2022_b_1.jpeg';
import B_2 from '../../../images/culture/benefits/b_2.png';
import B_3 from '../../../images/culture/benefits/b_3.png';
import B_4 from '../../../images/culture/benefits/b_4.png';
import C_1 from '../../../images/culture/benefits/c_1.png';
import C_2 from '../../../images/culture/benefits/c_2.png';
import D from '../../../images/culture/benefits/d.jpg';
import M_B_2 from '../../../images/culture/benefits/m_b_2.png';
import M_B_3 from '../../../images/culture/benefits/m_b_3.png';
import M_B_4 from '../../../images/culture/benefits/m_b_4.png';
import M_C_1 from '../../../images/culture/benefits/m_c_1.png';
import M_C_2 from '../../../images/culture/benefits/m_c_2.png';
import M_D from '../../../images/culture/benefits/m_d.jpg';
import DATA from '../../../data';

const IMAGE_SET = {
  a: {
    pc: [A_1, A_2, A_3],
    mobile: [A_1, A_2, A_3],
  },
  b: {
    pc: [B_1, B_2, B_3, B_4],
    mobile: [B_1, M_B_2, M_B_3, M_B_4],
  },
  c: {
    pc: [C_1, C_2],
    mobile: [M_C_1, M_C_2],
  },
  d: {
    pc: D,
    mobile: M_D,
  },
};

function Benefits() {
  const browserWidth = useBrowserWidth();
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={wrap}>
      <div className="container-md">
        <Info
          subTitle="Benefits & Perks"
          title="고객에 몰입할 수 있는<br />최적의 업무 환경을<br />제공합니다."
          isFullWidth
        >
          개인의 능률을 높이기 위해서 투자를 아끼지 않습니다. 최고 사양의 장비를 활용해 빠르게 빌드하고,
          작업물을 공유할 수 있도록 합니다. 또 장소와 시간에 구애받지 않고 본인의 컨디션에 따라 업무할 수 있습니다.
          서로의 역량과 Ownership에 대한 깊은 신뢰를 토대로 최대한의 자율성을 보장합니다.
        </Info>
      </div>
      <div className={
        classNames({ 'container-md': !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) })
      }
      >
        <div className={articleLayout}>
          <article
            className={
              classNames(
                article,
                { [`${imgPortrait}`]: !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) },
              )
            }
          >
            <div
              className={classNames(imgLayout, 'onlyPC')}
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.a.pc[0]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                />
                <img
                  src={IMAGE_SET.a.pc[1]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                />
                <img
                  src={IMAGE_SET.a.pc[2]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                />
              </Slider>
            </div>
            <div
              className={classNames(imgLayout, 'onlyMobile')}
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.a.mobile[0]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                />
                <img
                  src={IMAGE_SET.a.mobile[1]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                  vertical
                />
                <img
                  src={IMAGE_SET.a.mobile[2]}
                  alt="뱅크샐러드 복지 업무의 집중을 돕는 환경"
                />
              </Slider>
            </div>
            <BenefitsContents
              classProp={contentsLayout}
              title="업무의 집중을 돕는 다양하고<br class='onlyMobile'/> 유연한 근무 환경"
            >
              <p>
                적당한 소음이 있는 곳에서 일하는 게 좋은 사람이 있는 반면 아주 조용한 곳에서 일의 능률이 오르는 사람도 있습니다.
                맞춤화된 내 자리, 몰입할 수 있는 1인룸, 장시간 작업에 환기를 느낄 수 있는 스탠딩 테이블.
                자신에게 딱 맞는 업무 공간에서 일할 수 있습니다.
                사무실이 갑갑하게 느껴진다면 출근 후 리모트 제도를 활용해 업무 능률을 향상시켜 보세요!
              </p>
              <p>
                뱅크샐러드에서는 유연근무제를 통해 출/퇴근 시간에 구애받지 않고 업무를 할 수도 있습니다.
                일이 술술 잘 풀리는 날엔 조금 늦게 퇴근하기도 하고, 컨디션이 좋지 않은 날엔 일찍 퇴근하기도 합니다.
                단, 원활한 협업을 위해 코어타임은 지켜주셔야 해요!
                11AM - 4PM에는 필수로 근무하고, 그 외 시간에 유연하게 출퇴근하시면 됩니다.
              </p>
            </BenefitsContents>
          </article>
          <article
            className={
              classNames(
                article,
                { [`${imgPortrait}`]: !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) },
              )
            }
          >
            <BenefitsContents
              classProp={contentsLayout}
              title="언제든 에너지 충전이 가능한<br class='onlyMobile'/> 사내 휴게시설"
            >
              <p>
                뱅크샐러드에서는 구성원들의 재충전을 위한 휴게 장소를 쉽게 찾아볼 수 있습니다.
                계속 책상에만 앉아 일하다 보면 누구나 충전이 필요할 때가 있죠.
                각종 간식이 무한 제공되는 미니 스낵바에서는 각종 간식과 함께 커피, 음료수, 차 등 다양한 음료를 제공합니다.
                요청하는 간식들과 끼니 대용 음식들로 가득 찬 스낵바에서 활력을 얻어보아요.
              </p>
              <p>
                구성원의 에너지 충전을 위해 점심 식사도 제공합니다. 매일 점심 메뉴를 고민할 필요도 없어요!
              </p>
            </BenefitsContents>
            <div
              className={classNames(imgLayout, 'onlyPC')}
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.b.pc[0]}
                  alt="뱅크샐러드 복지 미니편의점"
                />
                <img
                  src={IMAGE_SET.b.pc[1]}
                  alt="뱅크샐러드 복지 쉼터"
                />
                <img
                  src={IMAGE_SET.b.pc[2]}
                  alt="뱅크샐러드 복지 카페테리아"
                />
                <img
                  src={IMAGE_SET.b.pc[3]}
                  alt="뱅크샐러드 복지 카페테리아"
                />
              </Slider>
            </div>
            <div
              className={classNames(imgLayout, 'onlyMobile')}
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.b.mobile[0]}
                  alt="뱅크샐러드 복지 미니편의점"
                />
                <img
                  src={IMAGE_SET.b.mobile[1]}
                  alt="뱅크샐러드 복지 쉼터"
                />
                <img
                  src={IMAGE_SET.b.mobile[2]}
                  alt="뱅크샐러드 복지 카페테리아"
                />
                <img
                  src={IMAGE_SET.b.mobile[3]}
                  alt="뱅크샐러드 복지 카페테리아"
                />
              </Slider>
            </div>
          </article>
          <article
            className={
              classNames(
                article,
                { [`${imgPortrait}`]: !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) },
              )
            }
          >
            <div
              className={classNames(imgLayout, 'onlyPC')}
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.c.pc[0]}
                  alt="뱅크샐러드 복지 필요한 장비 모두 지원"
                />
                <img
                  src={IMAGE_SET.c.pc[1]}
                  alt="뱅크샐러드 복지 업무 효율을 위한 회의실 세팅"
                />
              </Slider>
            </div>
            <div
              className={classNames(imgLayout, 'onlyMobile')}
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Slider {...settings}>
                <img
                  src={IMAGE_SET.c.mobile[0]}
                  alt="뱅크샐러드 복지 필요한 장비 모두 지원"
                />
                <img
                  src={IMAGE_SET.c.mobile[1]}
                  alt="뱅크샐러드 복지 업무 효율을 위한 회의실 세팅"
                />
              </Slider>
            </div>
            <BenefitsContents
              classProp={contentsLayout}
              title="최고의 장비 및 운영비 지원"
              lists={[
                '개발/디자인 직군 CTO 업그레이드 맥북 지급',
                '비즈니스 직군 윈도우 노트북과 맥북 프로 중 택일',
                '전 직군 최신 모니터 및 기타 업무 필요 장비 지급',
                'Sketch, Thinkcell 등 유료 소프트웨어 지원',
                '업무에 필요한 운영비, 야근 택시비 등 지원',
              ]}
            >
              우린 최고의 장비들이 구성원에게 날개를 달아 줄 것이라 믿습니다.
              장비 문제로 어려움을 겪지 않도록 최고 사양의 노트북과 모니터를 지급하며,
              본인의 업무 성격에 맞게 종류 선택도 가능합니다.
              또 개인의 역량 증진과 업무 생산성 향상을 위한 지원을 아끼지 않아요.
              업무에 필요한 소프트웨어 등이 있다면 언제든 요청하세요!
            </BenefitsContents>
          </article>
          <article
            className={
              classNames(
                article,
                { [`${imgPortrait}`]: !browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) },
              )
            }
          >
            <BenefitsContents
              classProp={contentsLayout}
              title="리프레쉬를 위한 충전의 시간"
              lists={[
                '반기별 Refresh day 휴일 별도 제공 (연 2일)',
                '전사 여름휴가 3일 별도 제공',
                '3년 근속 시 안식휴가 10일 별도 제공',
                '웰컴기간 종료 시 휴가 1일 별도 제공',
              ]}
            >
              <p>
                일을 잘하는 것만큼이나 잘 쉬는 것 또한 중요합니다. 몸과 마음이 건강해야 일도 잘할 수 있으니까요.
              </p>
              <p>
                뱅크샐러드는 기본적으로 보장된 연차 이외에도 구성원의 리프레쉬를 위해 별도 휴가를 제공합니다.
              </p>
            </BenefitsContents>
            <img
              className={classNames(imgLayout, 'onlyPC')}
              src={IMAGE_SET.d.pc}
              alt="뱅크샐러드 복지 자유로운 연차사용"
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            />
            <img
              className={classNames(imgLayout, 'onlyMobile')}
              src={IMAGE_SET.d.mobile}
              alt="뱅크샐러드 복지 자유로운 연차사용"
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            />
          </article>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
