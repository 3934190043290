import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import Culture from '../containers/Culture';

const CulturePage = () => (
  <Layout>
    <Head
      title="뱅크샐러드 | Culture"
      description="오로지 고객 임팩트에만 몰입하는 회사 -- 뱅크샐러드의 가치, 조직 문화, 구조 및 승진 제도에 대해 알아보세요!"
      keywords={['뱅크샐러드', '레이니스트', '채용', '문화', '조직', '컬쳐', '컬처', '분위기', '조직구조', '인재상', '보상', '연봉', '일하는 방식', '트라이브', '스쿼드', '스타트업', '마이데이터']}
    />
    <Culture />
  </Layout>
);

export default CulturePage;
