import React from 'react';

import classNames from 'classnames';
import {
  wrap, hover, title as styledTitle, arrowIcon, contents,
} from './style.module.css';

const Content = ({
  key,
  title,
  children,
  onClick,
}) => (
  <article
    key={key}
    className={classNames(wrap, 'col-sm-6', 'col-md-6', 'col-lg-4', { [`${hover}`]: onClick })}
    onClick={onClick}
  >
    <h1 className={styledTitle}>
      {title}
      {onClick && <div className={arrowIcon} />}
    </h1>
    <div className={contents}>
      {children}
    </div>
  </article>
);

export default Content;
